import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React from "react";
import MainLayout from "../../../layouts/main/main_layout";
import { ArrowBack, Download } from "@mui/icons-material";
import Loading from "../../../components/loading/loading";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import XlsIcon from "../../../assets/xls_icon.svg";
import {
  downloadChoice,
  getChoiceUserDetails,
} from "../../../services/choices/choices_services";
import { useQuery } from "react-query";
import { downloadFromBlob } from "../../../utils/utils";
import { ResponseDetails } from "../model/response_details";
import "survey-react/survey.css";
import SurveyMedia from "../../../components/survey_media/survey_media";

export default function ResponseDetailPage() {
  const [currentTab, setCurrentTab] = React.useState(0);
  let { id } = useParams();
  let { state } = useLocation();
  const { data: responseData, isLoading } = useQuery<ResponseDetails>(
    ["viewResponseDetails"],
    async () => {
      let response = await getChoiceUserDetails(+id!, +state.id!);
      return response.data.data || [];
    }
  );

  const handleChange = (event: React.SyntheticEvent, currentTabVal: number) => {
    setCurrentTab(currentTabVal);
  };

  const downloadViewChoiceApiCall = () => {
    downloadChoice({
      choiceAnswersId: +id!,
      format: "excel",
      choiceId: +state?.id!,
    })
      .then((res) => {
        let extension = ".xlsx";
        downloadFromBlob(res, extension);
      })
      .catch((e) => { });
  };

  const navigate = useNavigate();

  const renderCompoBasedOnTheTab = () => {
    switch (currentTab) {
      case 0:
        return personalDetails();
      case 1:
        return locationDetails();
      case 2:
        return otherDetails();
      case 3:
        return durableDetails();
    }
  };

  function answerType({ type, response }: { type: string; response: any }) {
    switch (type) {
      case null:
        return (
          (typeof response === "string" || typeof response === "boolean") && (
            <Box>
              <Typography>{response?.toString()}</Typography>
            </Box>
          )
        );
      case "text":
      case "comment":
      case "radiogroup":
      case "rating":
      case "checkbox":
      case "dropdown":
      case "tagbox":
      case "ranking":
      case "boolean":
        return (
          <Box>
            <Typography>{response?.toString()}</Typography>
          </Box>
        );
      case "multipletext":
        const objectValuesArray = Object.values(response);
        return (
          <Box>
            {objectValuesArray.map((value: any, index: any) => (
              <Typography>{value}</Typography>
            ))}
          </Box>
        );
      case "matrix":
        return (
          <Box>
            {Object.keys(response).map((qsn) => {
              return (
                <Typography>{`${qsn} : ${response[`${qsn}`]}`}</Typography>
              );
            })}
            <Typography></Typography>
          </Box>
        );
      case "matrixdropdown":
        return (
          <Box>
            {Object.keys(response).map((qsn) => {
              return (
                <Box display={"flex"}>
                  <Typography>{`${qsn} : `}</Typography>
                  {Object.keys(response[qsn]).map((item) => (
                    <Typography mx={1}>
                      {` ${item} -> ${response[qsn][item]}`}
                    </Typography>
                  ))}
                </Box>
              );
            })}
            <Typography></Typography>
          </Box>
        );
      case "matrixdynamic":
        return (
          <Box>
            {response.map((item: any) => {
              return Object.keys(item).map((qsnKey: any) => {
                return (
                  <Typography>{`${qsnKey} : ${item[`${qsnKey}`]}`}</Typography>
                );
              });
            })}
            <Typography></Typography>
          </Box>
        );
      case "file":
        return (
          <Box>
            {response.map((item: any) => {
              return (
                <Box py={1}>
                  <Box display="flex" alignItems="center">
                    <Typography variant="body2">{item.name}</Typography>
                    &nbsp;&nbsp;{" "}
                    <a href={item.content} download style={{ textDecoration: 'none' }} target="_blank">
                      <IconButton size="small">
                        <Download />
                      </IconButton>
                    </a>
                  </Box>

                  {/* <SurveyMedia url={item.content} type={item.type} /> */}
                </Box>
              );
            })}
            <Typography></Typography>
          </Box>
        );
    }
  }

  function personalDetails() {
    return (
      <>
        {/* <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "UID",
            value: responseData?.user?.id,
          })}
        </Grid> */}
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Full Name",
            value: responseData?.userDetails?.name,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Date of Birth",
            value: responseData?.userDetails?.dob,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Gender",
            value: responseData?.userDetails?.gender,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Marital Status  (Optional)",
            value: responseData?.userDetails?.maritalStatus,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Email  (Optional)",
            value: responseData?.userDetails?.email,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Mobile Numer",
            value: responseData?.userDetails?.mobileNumber,
          })}
        </Grid>
        {/* <Grid item md={6} lg={6} sm={12} xs={12}></Grid> */}
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Family Income",
            value: responseData?.userDetails?.familyIncome,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Subscribed to Newsletter",
            value: "",
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Newsletter Email ID",
            value: "",
          })}
        </Grid>
      </>
    );
  }
  function locationDetails() {
    return (
      <>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "House/Flat/Room No. (Optional)",
            value: responseData?.locationDetails?.addressLine1,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Building/Chawl Name/No. (Optional)",
            value: responseData?.locationDetails?.addressLine2,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Street/Road No./Name (Optional)",
            value: responseData?.locationDetails?.addressLine3,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Locality/Area",
            value: responseData?.locationDetails?.area,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Landmark (Optional)",
            value: responseData?.locationDetails?.landmark,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "State",
            value: responseData?.locationDetails?.state,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "District",
            value: responseData?.locationDetails?.district,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "PIN Code",
            value: responseData?.locationDetails?.pincode,
          })}
        </Grid>
      </>
    );
  }

  function otherDetails() {
    return (
      <>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Educational Qualification",
            value: responseData?.otherDetails?.education,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Working Status",
            value: responseData?.otherDetails?.workingStatus,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Occupation",
            value: responseData?.otherDetails?.occupation,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Family Type",
            value: responseData?.otherDetails?.familtyType,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Ownership of House",
            value: responseData?.otherDetails?.houseOwnership,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Personal Income",
            value: responseData?.otherDetails?.personalIncome,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Family Income",
            value: responseData?.otherDetails?.familyIncome,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}></Grid>
      </>
    );
  }

  function durableDetails() {
    return (
      <Grid item md={6} lg={6} sm={12} xs={12}>
        <Typography color="primary.main">{"Durable details"}</Typography>
        {responseData?.durableDetails?.length === 0 ? (
          <Typography>-</Typography>
        ) : (
          responseData?.durableDetails?.map((durableItems) => {
            return <Typography>{durableItems}</Typography>;
          })
        )}
      </Grid>
    );
  }

  function listItem({
    title,
    value,
  }: {
    title: string;
    value: any;
  }): JSX.Element {
    return (
      <Box>
        <Typography color="primary.main">{title}</Typography>
        <Typography>{value || "-"}</Typography>
      </Box>
    );
  }

  function divider() {
    return (
      <Divider
        sx={{
          display: "flex",
          opacity: 0.5,
          borderBottom: "1px dotted #7C797C",
        }}
      />
    );
  }

  function IconComponent(
    imgSrc: string,
    backgroundColor: string,
    onTap?: () => void
  ) {
    return (
      <Box
        sx={{
          display: "flex",
          backgroundColor: "#FAEDEA",
          justifyContent: "center",
          alignItems: "center",
          p: 1,
          borderRadius: 3,
          ml: 2,
        }}
      >
        <IconButton
          onClick={onTap}
          sx={{
            height: 24,
            width: 24,
          }}
        >
          <img src={imgSrc} alt="" />
        </IconButton>
      </Box>
    );
  }
  return (
    <MainLayout>
      <Grid container mt={4}>
        <Grid item lg={8} md={12} sm={12} xs={12} xl={8}>
          {isLoading ? (
            <Card
              sx={{
                display: "flex",
                minHeight: 500,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box>
                <Loading />
              </Box>
            </Card>
          ) : (
            <Card sx={{ px: 2, pb: 2 }}>
              <Box display={"flex"} alignItems={"center"} py={2}>
                <IconButton onClick={() => navigate(-1)}>
                  <ArrowBack />
                </IconButton>
                <Typography fontWeight={"600"} m={1}>
                  Choice response
                </Typography>
              </Box>
              {divider()}

              <Tabs
                value={currentTab}
                onChange={handleChange}
                sx={{
                  "MuiTabs-root css-112qdbf-MuiTabs-root": {
                    borderColor: "#F5E6F5",
                  },
                  mt: 2,
                }}
              >
                <Tab
                  label="Personal details"
                  sx={{
                    textTransform: "capitalize",
                    color: "#1B001B",
                  }}
                />
                <Tab
                  label="Location details"
                  sx={{ textTransform: "capitalize", color: "#1B001B" }}
                />
                <Tab
                  label="Other details"
                  sx={{ textTransform: "capitalize", color: "#1B001B" }}
                />
                <Tab
                  label="Durable details"
                  sx={{ textTransform: "capitalize", color: "#1B001B" }}
                />
              </Tabs>
              <Box>
                <Grid container spacing={3} mt={1}>
                  {renderCompoBasedOnTheTab()}
                </Grid>
              </Box>

              <Box mt={4}>{divider()}</Box>
              <Box mt={2}>
                <Typography variant="subtitle1" fontWeight={"600"}>
                  Question and Answers
                </Typography>
              </Box>
              <Box>
                <Card
                  sx={{
                    height: 350,
                    overflowY: "scroll",
                    p: 2,
                    borderRadius: 5,

                    "&::-webkit-scrollbar": {
                      width: "8px",
                    },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "#F5E6F5",
                      borderRadius: "6px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#DFB0DF",
                      borderRadius: "6px",
                    },
                  }}
                >
                  {responseData?.choiceDetails?.answerList?.map(
                    (
                      items: {
                        question: string;
                        questionType: string;
                        response: any;
                      },
                      index: any
                    ) => {
                      return (
                        items.response && (
                          <Box mb={1}>
                            <Typography color="#91278F" variant="body2">
                              {index + 1}. {items?.question}
                            </Typography>
                            {answerType({
                              type: items.questionType,
                              response: items.response,
                            })}
                          </Box>
                        )
                      );
                    }
                  )}
                </Card>
              </Box>
              <Box
                display="flex"
                flexDirection={"row"}
                mt={1}
                alignItems={"center"}
              >
                <Typography variant="subtitle2">Generate Report: </Typography>
                {IconComponent(XlsIcon, "#FAEDEA", () => {
                  downloadViewChoiceApiCall();
                })}
              </Box>
              <Box mt={3}>
                <Button onClick={() => navigate(-1)} variant="outlined">
                  Back To Responses
                </Button>
              </Box>
            </Card>
          )}
        </Grid>
      </Grid>
    </MainLayout>
  );
}
