import { ArrowBack } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { Question, Serializer, surveyLocalization } from "survey-core";
import { SurveyCreator } from "survey-creator-react";
import MainLayout from "../../../layouts/main/main_layout";
import { useFeedback } from "../../../providers/feedback/feeedback";
import {
  getChoice,
  getChoiceQuestions,
  getMasterData,
  submitChoices,
} from "../../../services/choices/choices_services";
import ChoiceQuestionnaire from "../components/choice_quitionnaire/choice_questionnaire";
import EligibilityCriteria from "../components/eligibility_criteria/eligibility_criteria";
import Stepper from "../components/stepper/stepper";
import { ChoiceFormInput } from "../model/choice_form";
import { ChoiceDetails } from "../model/choices_Details";
import { PageModel } from "survey-react";

Serializer.getProperty("file", "storeDataAsText").visible = false;
Serializer.getProperty("file", "storeDataAsText").defaultValue = false;
Serializer.getProperty("file", "showCommentArea").visible = false;
Serializer.getProperty("file", "allowMultiple").visible = false;
Serializer.getProperty("file", "allowMultiple").defaultValue = false;
Serializer.getProperty("file", "allowCameraAccess").defaultValue = false;
// Serializer.getProperty("file", "acceptedTypes").visible = true;
// Serializer.getProperty("file", "sourceType").visible = true;

interface CreateChoiceInterface {
  viewOnly?: boolean;
}

export default function CreateNewChoices({ viewOnly }: CreateChoiceInterface) {
  const { state } = useLocation();
  const [eligibiltyData, setEligibilityData] = useState<ChoiceFormInput | null>(
    null
  );
  const [completedSteps, setCompletedSteps] = useState(1);
  const stepper: { id: number; label: string }[] = [
    { id: 1, label: "Eligibility Criteria" },
    { id: 2, label: "Choice Questionnaire" },
  ];
  const navigate = useNavigate();
  const { showSnackbar } = useFeedback();

  const { data: choicesMasterData, isLoading } = useQuery(
    ["choiceMasterData"],
    async () => {
      let response = await getMasterData();
      return response?.data?.data || [];
    }
  );

  const { data: choiceDetails } = useQuery<ChoiceDetails>(
    ["choice-detail", state?.id],
    async () => {
      if (state?.id) {
        let response = await getChoice(+state?.id!);
        return response?.data?.data || [];
      }
    }
  );

  const { data: choiceQuestions } = useQuery<any>(
    ["choice-question", state?.id],
    async () => {
      if (state?.id) {
        let response = await getChoiceQuestions(+state?.id!);
        return response?.data?.data || [];
      }
    }
  );

  const creator = useMemo(
    () =>
      new SurveyCreator({
        showLogicTab: true,
        isAutoSave: true,
        haveCommercialLicense: true,
        showTranslationTab: true,
        showJSONEditorTab: true,
        questionTypes: [
          "boolean",
          "checkbox",
          "comment",
          "dropdown",
          "tagbox",
          "matrix",
          "matrixdropdown",
          "matrixdynamic",
          "multipletext",
          "radiogroup",
          "rating",
          "ranking",
          "text",
          "imagepicker",
          "html",
          "file",
        ],
      }),
    []
  );

  useEffect(() => {

    Serializer.addProperty("question", {
      name: "isImportant:boolean",
      default: false,
      category: "general",
      isSerializable: true,
    });

    creator.onQuestionAdded.add((sender, options) => {
      const question = options.question;
      if (question) {
        question.isImportant = question.isImportant ?? false;
      }
    });

  }, [creator]);

  useMemo(
    () => (creator.JSON = choiceQuestions?.details || {}),
    [choiceQuestions]
  );

  useEffect(() => {
    surveyLocalization.supportedLocales = [
      "en",
      "hi",
      "kn",
      "te",
      "ta",
      "bn",
      "ml",
      "mr",
      "gu",
      "pa",
      "or",
      "as",
    ];
  }, []);

  function submitChoiceFunc() {
    let questionObject = creator.JSON;
    let validFrom = eligibiltyData?.surveyValidTo
      ? dayjs(eligibiltyData?.surveyValidFrom, "YYYY-MM-DD")
        .format("MM-DD-YYYY")
        .toString()
      : null;
    let validTo = eligibiltyData?.surveyValidTo
      ? dayjs(eligibiltyData?.surveyValidTo, "YYYY-MM-DD")
        .format("MM-DD-YYYY")
        .toString()
      : null;
    let expiry = {
      validFrom: validFrom,
      validTo: validTo,
    };
    let eligibility = {
      genders: eligibiltyData?.eligibleGender?.map((item) => item),
      fromAge: eligibiltyData?.fromAge,
      toAge: eligibiltyData?.toAge,
      stateIds: eligibiltyData?.eligibleStates?.map((item) => item),
      districtIds:
        eligibiltyData?.eligibleDsitricts?.length === 0
          ? null
          : eligibiltyData?.eligibleDsitricts?.map((item) => item),
      residenceIds: eligibiltyData?.areaOfResidence,
      casteIds: eligibiltyData?.eligibleCaste,
      workingStatusIds: eligibiltyData?.eligibleEmploymentStatus,
      occupationIds: eligibiltyData?.eligibleOccupation?.map((item) => item),
      maritalStatusIds: eligibiltyData?.eligibleMaritalStatus?.map(
        (item) => item
      ),
      personalIncomeIds: eligibiltyData?.personalIncome?.map((item) => item),
      familyIncomeIds: eligibiltyData?.familyIncome?.map((item) => item),
      educationIds:
        eligibiltyData?.educationQualification?.length === 0
          ? null
          : eligibiltyData?.educationQualification?.map((item) => item),
      familyTypeIds: eligibiltyData?.familyType?.map((item) => item),
      houseOwnershipIds: eligibiltyData?.ownershipHouse?.map((item) => item),
      durableOwnershipIds:
        eligibiltyData?.durableOwnership?.length === 0
          ? null
          : eligibiltyData?.durableOwnership,
      areasOfInterestIds: eligibiltyData?.areasOfInterest,
      differentlyAbled:
        eligibiltyData?.differentlyEbledOnly === undefined
          ? null
          : eligibiltyData?.differentlyEbledOnly,
      minority:
        eligibiltyData?.minorityOnly === undefined
          ? null
          : eligibiltyData?.minorityOnly,
      bplCategory:
        eligibiltyData?.bplCardOnly === undefined
          ? null
          : eligibiltyData?.bplCardOnly,
      student:
        eligibiltyData?.studentsOnly === undefined
          ? null
          : eligibiltyData?.studentsOnly,
      isImageCapture: eligibiltyData?.allowImage,
      isVideoCapture: eligibiltyData?.allowVideoCap,
      vendorEmailId: eligibiltyData?.vendorsEmailId,
      nccsGrades:
        eligibiltyData?.nccsGrades?.length === 0
          ? null
          : eligibiltyData?.nccsGrades,
    };
    let questions = {
      title: questionObject?.title,
      description: questionObject?.description,
      logoPosition: questionObject?.logoPosition,
      pages: questionObject?.pages,
    };

    let payload = {
      expiry: expiry,
      eligibility: eligibility,
      questions: questions,
    };

    let isTranslationExistsData =
      questions?.pages?.find((page: any) => {
        return page.elements?.find((element: any) => {
          return typeof element?.title == "object" && !element?.title?.default;
        });
      }) || [];

    let isTitleExists =
      typeof questions?.title === "object"
        ? !!questions?.title?.default
        : !!questions?.title;
    let isDescriptionExists =
      typeof questions?.description === "object"
        ? !!questions?.description?.default
        : !!questions?.description;

    if (!isTitleExists) {
      showSnackbar("Please enter title for the survey");
    } else if (!isDescriptionExists) {
      showSnackbar("Please enter description for the survey");
    } else if (isTranslationExistsData?.length !== 0) {
      showSnackbar(
        `Please enter default translation for ${isTranslationExistsData?.elements?.[0]?.name}`
      );
    } else {

      submitChoices(payload)
        .then((res) => {
          if (res.status === 200) {
            showSnackbar("Survey created successfully");
            navigate("/choices");
          }
        })
        .catch((e) => {
          if (e.response?.data?.error?.code === 445) {
            showSnackbar("Survey already exist");
          } else {
            showSnackbar("Something went wrong");
          }
        });
    }
  }

  const onSubmit: SubmitHandler<ChoiceFormInput> = (data) => {
    if (viewOnly) {
      console.log(data);
    } else {
      if (
        (data?.nccsGrades?.length === 0 || data?.nccsGrades === undefined) &&
        (data?.educationQualification?.length === 0 ||
          data?.educationQualification === undefined) &&
        (data?.durableOwnership?.length === 0 ||
          data?.durableOwnership === undefined)
      ) {
        showSnackbar(
          "Please select either NCCS grades or Durable Ownership and Education Qualification"
        );
      } else if (
        (data?.educationQualification?.length === 0 ||
          data?.educationQualification === undefined) &&
        (data?.nccsGrades === undefined || data?.nccsGrades?.length === 0)
      ) {
        showSnackbar("Please select Education Qualification");
      } else if (
        (data?.durableOwnership?.length === 0 ||
          data?.durableOwnership === undefined) &&
        (data?.nccsGrades === undefined || data?.nccsGrades?.length === 0)
      ) {
        showSnackbar("Please select Durable Ownership");
      } else {
        setCompletedSteps(2);
        setEligibilityData(data);
      }
    }
  };

  return (
    <MainLayout>
      <Box display={"flex"} mx={2}>
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            borderRadius: 2,
            m: 1,
            minHeight: 550,
          }}
          elevation={1}
        >
          <CardContent sx={{ flexGrow: 1, height: "100%" }}>
            <Box display="flex" flexDirection="column" sx={{ height: "100%" }}>
              <Box flexDirection="row" display="flex" alignItems="center">
                <IconButton onClick={() => navigate(-1)}>
                  <ArrowBack></ArrowBack>
                </IconButton>
                <Typography variant="h6" fontWeight="600">
                  {"Create new choice"}
                </Typography>
              </Box>
              <Divider sx={{ my: 2 }} />
              {!viewOnly && (
                <Stepper
                  stepperValues={stepper}
                  completedSteps={completedSteps}
                />
              )}
              {completedSteps === 1 && (
                <EligibilityCriteria
                  choicesMasterData={choicesMasterData}
                  compleatedSteps={completedSteps}
                  setCompleatedSteps={setCompletedSteps}
                  onSubmit={onSubmit}
                  choiceDetails={choiceDetails || null}
                  eligibiltyData={eligibiltyData || {}}
                />
              )}
              {completedSteps === 2 && (
                <ChoiceQuestionnaire
                  surveyCreator={creator}
                  submitSurvey={submitChoiceFunc}
                  onSubmit={onSubmit}
                  setCompleatedSteps={setCompletedSteps}
                />
              )}
            </Box>
          </CardContent>
        </Card>
      </Box>
    </MainLayout>
  );
}
