import {
  ArrowBack,
  Directions,
  DownloadOutlined,
  EditOutlined,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Rating,
  TextField,
  Typography,
} from "@mui/material";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import AttachmentIcon from "../../../assets/attachment.svg";
import PDFIcon from "../../../assets/ph_file-pdf.svg";
import XLSIcon from "../../../assets/ph_file-xls.svg";
import Loading from "../../../components/loading/loading";
import MainLayout from "../../../layouts/main/main_layout";
import {
  downloadNeed,
  getArea,
  getBrand,
  getNeed,
  getNeedsFilters,
  patchNeedStatus,
} from "../../../services/needs/needs_service";
import { purpleLight } from "../../../theme/colors";
import { downloadFromBlob } from "../../../utils/utils";
import StatusMenu from "../components/status_menu/status_menu";
import { Need } from "../model/need";
import { useEffect, useState } from "react";
import { queryClient } from "../../../providers/query/query";
import UpdateNeedStatus from "../components/update_need_status/update_need_status";
import { NeedFilters, Option } from "../model/needs_filters";
import useUser from "../../../providers/user/user_provider";

export default function Details() {
  const navigate = useNavigate();
  const [needStatus, setNeedStatus] = useState<string | null>(null);
  const [isNeedStatusChanged, setNeedStatusChanged] = useState<boolean>(false);
  const [closeNeedStatusDialog, setCloseNeedStatusDialog] =
    useState<boolean>(false);
  const [modifyEnabled, setModifyEnabled] = useState(false);
  const [_categoryId, _setCategoryId] = useState<number | null>(null);
  const [_subCategoryId, _setSubcategoryId] = useState<number | null>(null);
  const [_brand, _setBrandId] = useState<number | null>(null);
  const [_areaId, _setAreaId] = useState<number | null>(null);
  const [internalNotes, setInternalNotes] = useState("");
  const [brands, setBrands] = useState([]);
  const [areas, setAreas] = useState([]);
  const [editInternalNote, setEditInternalNote] = useState(false);

  const getAreaApiCall = () => {
    getArea({ categoryId: _categoryId, subcategoryId: _subCategoryId }).then(
      (res) => {
        setAreas(res.data.data.list);
      }
    );
  };

  const getGrandsApiCall = () => {
    getBrand({ categoryId: _categoryId, subcategoryId: _subCategoryId }).then(
      (res) => {
        setBrands(res.data.data.list);
      }
    );
  };

  let { id } = useParams();
  const { hasPermission } = useUser();
  const { data: needDetails, isLoading } = useQuery<Need>(
    ["need-details", id],
    async () => {
      let data = await getNeed(id!);

      return data?.data?.data?.data;
    }
  );

  useEffect(() => {
    if (_subCategoryId) {
      getGrandsApiCall();
      getAreaApiCall();
    }
  }, [_subCategoryId]);

  useEffect(() => {
    _setCategoryId(needDetails?.systemCategoryId || null);
    _setSubcategoryId(needDetails?.systemSubCategoryId || null);
    _setAreaId(needDetails?.systemAreaId || null);
    _setBrandId(needDetails?.systemBrandId || null);
    setInternalNotes(needDetails?.systemNotes || "");
    console.log(needDetails?.systemNotes);
  }, [needDetails]);

  const { data: needsCategory } = useQuery<NeedFilters>(
    ["needs-filters"],
    async () => {
      let data = await getNeedsFilters();

      return data?.data?.data;
    }
  );

  function getSubCategories(): Option[] {
    return _categoryId
      ? needsCategory?.categories?.find(
        (category) => category.category._id === _categoryId
      )?.subcategory || []
      : [];
  }

  function listItem({
    title,
    value,
  }: {
    title: string;
    value: any;
  }): JSX.Element {
    return (
      <Box>
        <Typography color="primary.main">{title}</Typography>
        <Typography>{value || "-"}</Typography>
      </Box>
    );
  }
  function loading() {
    return (
      <Box
        height={300}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Loading />
      </Box>
    );
  }

  function downloadPdf() {
    downloadNeed(id!, "pdf").then((response) => {
      downloadFromBlob(response, new Date().valueOf() + ".pdf");
    });
  }

  function downloadExcel() {
    downloadNeed(id!, "excel").then((response) => {
      downloadFromBlob(response, new Date().valueOf() + ".xlsx");
    });
  }

  function updateNeedStatus() {

    if (needStatus === "In Progress") {
      const formData = new FormData();
      formData.append("status", "2");
      if (internalNotes) {
        formData.append("notes", internalNotes);
      }
      patchNeedStatus(id!, formData).then(() => {
        queryClient.invalidateQueries({ queryKey: ["need-details", id] });
        setNeedStatusChanged(false);
        setEditInternalNote(false);
        setInternalNotes("");
      });
    } else if (needStatus === "Closed") {
      setCloseNeedStatusDialog(true);
    } else if (needStatus === "Submitted") {
      const formData = new FormData();
      formData.append("status", "1");
      if (internalNotes) {
        formData.append("notes", internalNotes);
      }
      patchNeedStatus(id!, formData).then(() => {
        queryClient.invalidateQueries({ queryKey: ["need-details", id] });
        setNeedStatusChanged(false);
        setEditInternalNote(false);
        setInternalNotes("");
      });
    }
  }

  function updateNeedStatusToClosed(comments: string | null, file: File | null, notificationMode: string[]) {
    setCloseNeedStatusDialog(false);
    const formData = new FormData();
    formData.append("status", "3"); // The new status
    if (comments) {
      formData.append("comments", comments);
    }
    if (internalNotes) {
      formData.append("internalNotes", internalNotes);
    }
    if (file) {
      formData.append("file", file);
    }
    if (notificationMode.length)
      formData.append("type", JSON.stringify(notificationMode));
    patchNeedStatus(id!, formData).then(() => {
      queryClient.invalidateQueries({ queryKey: ["need-details", id] });
      setNeedStatusChanged(false);
      setEditInternalNote(false);
      setInternalNotes("");
    });
  }

  function updateNeedDetails() {
    const formData = new FormData();
    let status = "1"
    if (status === "In Progress") {
      status = "2"
    } else if (status === "Closed") {
      status = "3"
    }

    formData.append("status", status); // The
    if (_categoryId) {
      formData.append("sysgenCategory", _categoryId.toString());
    }
    if (_subCategoryId) {
      formData.append("sysgenSubCategory", _subCategoryId.toString());
    }
    if (_areaId) {
      formData.append("sysgenArea", _areaId.toString());
    }
    if (_brand) {
      formData.append("sysgenBrand", _brand.toString());
    }
    patchNeedStatus(
      id!,
      formData
    ).then(() => {
      queryClient.invalidateQueries({ queryKey: ["need-details", id] });
      _setCategoryId(null);
      _setAreaId(null);
      _setSubcategoryId(null);
    });
  }

  useEffect(() => {
    if (needDetails?.status) setNeedStatus(needDetails.status);
  }, [needDetails]);

  function _dropdownOption({
    title,
    placeholder,
    options,
    selectedId,
    onSelect,
    disabled,
  }: {
    title: string;
    placeholder: string;
    options: { id: number; value: string }[];
    selectedId: number | null;
    onSelect: (id: number | null) => void;
    disabled?: boolean;
  }) {
    return (
      <Box mr={4}>
        <Box pt={1} pb={2} display="flex" flexDirection="row">
          <Autocomplete
            disabled={disabled}
            fullWidth
            // multiple
            value={
              selectedId == null
                ? { id: null, value: "" }
                : options.find((option) => selectedId === option?.id)
            }
            onChange={(e, value) => {
              onSelect(value?.id || null);
            }}
            options={options}
            getOptionLabel={(option) => option?.value}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label={placeholder} />
            )}
          />
        </Box>
      </Box>
    );
  }

  return (
    <MainLayout>
      <Grid container spacing={3}>
        <Grid item md={8} lg={7} sm={12} xs={12}>
          <Card sx={{ width: "100%", borderRadius: 2, m: 1 }} elevation={1}>
            <CardContent>
              <Box display="flex" flexDirection="row">
                <Box width={200} display="flex" alignItems="center">
                  <IconButton onClick={() => navigate(-1)}>
                    <ArrowBack></ArrowBack>
                  </IconButton>
                  <Typography variant="h6" fontWeight="600" paddingLeft={1}>
                    Needs Details
                  </Typography>
                </Box>
                <Box
                  flex={1}
                  display="flex"
                  alignItems="center"
                  justifyContent="end"
                ></Box>
              </Box>
              <Box mt={2} mb={2}>
                <Divider />
              </Box>
              <Box m={2}>
                {isLoading ? (
                  loading()
                ) : (
                  <Box overflow="auto" flex={1}>
                    <Grid container spacing={3}>
                      <Grid item md={6}>
                        {listItem({
                          title: "Reference Number",
                          value: needDetails?.needId,
                        })}
                      </Grid>
                      <Grid item md={6}>
                        {listItem({
                          title: "Full Name",
                          value: needDetails?.name,
                        })}
                      </Grid>
                      <Grid item md={6}>
                        {listItem({
                          title: "Contact Number",
                          value: `+91 ${needDetails?.mobileNumber}`,
                        })}
                      </Grid>
                      <Grid item md={6}>
                        {listItem({
                          title: "Category",
                          value: needDetails?.category,
                        })}
                      </Grid>
                      <Grid item md={6}>
                        {listItem({
                          title:
                            needDetails?.category === "Corporate"
                              ? "Company"
                              : "Sub Category",
                          value: needDetails?.subCategory,
                        })}
                      </Grid>
                      <Grid item md={6}>
                        {listItem({
                          title: "Area",
                          value: needDetails?.area,
                        })}
                      </Grid>
                      {needDetails?.category === "Corporate" && (
                        <Grid item md={6}>
                          {listItem({
                            title: "Brand",
                            value: needDetails?.brand,
                          })}
                        </Grid>
                      )}
                    </Grid>
                    <Grid
                      container
                      rowGap={3}
                      my={2}
                      p={2}
                      sx={{ border: "1px solid #DFB0DF", borderRadius: 5 }}
                    >
                      {!modifyEnabled ? (
                        <>
                          <Grid item md={6}>
                            {listItem({
                              title: "SysGen Category",
                              value: needDetails?.systemCategory
                                ? needDetails?.systemCategory
                                : "NA",
                            })}
                          </Grid>
                          <Grid item md={6}>
                            {listItem({
                              title:
                                needDetails?.systemCategory === "Corporate"
                                  ? "SysGen Company"
                                  : "SysGen Sub Category",
                              value: needDetails?.systemSubCategory
                                ? needDetails?.systemSubCategory
                                : "NA",
                            })}
                          </Grid>
                          <Grid item md={6}>
                            {listItem({
                              title: "SysGen Area",
                              value: needDetails?.systemArea
                                ? needDetails.systemArea
                                : "NA",
                            })}
                          </Grid>
                          <Grid item md={6}>
                            {needDetails?.systemCategory === "Corporate" &&
                              listItem({
                                title: "SysGen Brand",
                                value: needDetails?.systemBrand,
                              })}
                          </Grid>
                          <Grid item md={4}>
                            <Button
                              disabled={needDetails?.status === 'Closed'}
                              variant="outlined"
                              fullWidth
                              onClick={() => setModifyEnabled(true)}
                            >
                              Modify
                            </Button>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item md={6}>
                            {_dropdownOption({
                              title: "SysGen Category",
                              placeholder: "Search for SysGen Category",
                              options:
                                needsCategory?.categories?.map((cat) => {
                                  return {
                                    id: cat.category._id,
                                    value: cat.category.name,
                                  };
                                }) || [],
                              selectedId: _categoryId,
                              onSelect: (id) => {
                                _setCategoryId(id);
                                _setSubcategoryId(null);
                                _setAreaId(null);
                                _setBrandId(null);
                              },
                            })}
                          </Grid>
                          <Grid item md={6}>
                            {_dropdownOption({
                              disabled: _categoryId === 3 ? true : false,
                              title:
                                _categoryId === 2
                                  ? "SysGen Company"
                                  : "SysGen Sub-Category",
                              placeholder:
                                _categoryId === 2
                                  ? "SysGen Company"
                                  : "SysGen Sub-Category",
                              options: getSubCategories()?.map((option) => {
                                return { id: option._id, value: option.name };
                              }),
                              selectedId: _subCategoryId,
                              onSelect: (id) => {
                                _setSubcategoryId(id);
                                _setBrandId(null);
                                _setAreaId(null);
                              },
                            })}
                          </Grid>
                          {_categoryId === 2 && (
                            <Grid item md={6}>
                              {_dropdownOption({
                                disabled: brands?.length === 0 ? true : false,
                                title: "SysGen Brand",
                                placeholder: "Search for SysGen Brand",
                                options: brands || [],
                                selectedId: _brand,
                                onSelect: (id) => {
                                  _setBrandId(id);
                                },
                              })}
                            </Grid>
                          )}
                          <Grid item md={6}>
                            {_dropdownOption({
                              disabled: areas?.length === 0 ? true : false,
                              title: "SysGen Area",
                              placeholder: "Search for SysGen Area",
                              options: areas || [],
                              selectedId: _areaId,
                              onSelect: (id) => {
                                _setAreaId(id);
                              },
                            })}
                          </Grid>
                          {_categoryId !== 2 && <Grid item md={6}></Grid>}
                          <Grid item md={4}>
                            <Button
                              variant="outlined"
                              fullWidth
                              onClick={() => {
                                updateNeedDetails();
                                setModifyEnabled(false);
                              }}
                            >
                              Save Changes
                            </Button>
                          </Grid>
                          <Grid item md={4}>
                            <Button
                              variant="outlined"
                              fullWidth
                              onClick={() => {
                                setModifyEnabled(false);
                              }}
                              sx={{ ml: 2 }}
                            >
                              Cancel
                            </Button>
                          </Grid>
                        </>
                      )}
                    </Grid>
                    <Grid container spacing={3}>
                      {/* <Grid item md={6}>
                        {listItem({
                          title: "SysGen Company",
                          value:
                            needDetails?.systemCategory === "Corporate"
                              ? needDetails?.systemSubCategory
                              : "NA",
                        })}
                      </Grid>
                      <Grid item md={6}>
                        {listItem({
                          title: "SysGen Brand",
                          value: needDetails?.systemBrand,
                        })}
                      </Grid> */}

                      <Grid item md={6}>
                        {listItem({
                          title: "Area",
                          value: needDetails?.area,
                        })}
                      </Grid>
                      <Grid item md={6}>
                        {listItem({
                          title: "Location",
                          value: needDetails?.address,
                        })}
                      </Grid>
                      <Grid item md={6}>
                        {listItem({
                          title: "State",
                          value: needDetails?.state,
                        })}
                      </Grid>
                      <Grid item md={6}>
                        {listItem({
                          title: "City",
                          value: needDetails?.city,
                        })}
                      </Grid>
                      <Grid item md={6}>
                        {listItem({
                          title: "District",
                          value: needDetails?.district,
                        })}
                      </Grid>
                      <Grid item md={12}>
                        {listItem({
                          title: "Description",
                          value: needDetails?.description,
                        })}
                      </Grid>
                      <Grid item md={12}>
                        {listItem({
                          title: "Action Expected",
                          value: needDetails?.action,
                        })}
                      </Grid>
                      {needDetails?.lon && needDetails?.lon && (
                        <Grid item md={12}>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Typography color="primary.main">
                              Location details
                            </Typography>
                            <a
                              target="_blank"
                              href={`https://www.google.com/maps/dir/?api=1&destination=${needDetails?.lat},${needDetails?.lon}`}
                              rel="noreferrer"
                            >
                              <Button
                                size="small"
                                variant="text"
                                endIcon={<Directions />}
                              >
                                Direction
                              </Button>
                            </a>
                          </Box>
                          <Box
                            height={160}
                            mt={1}
                            sx={{ borderRadius: 4, overflow: "hidden" }}
                          >
                            <iframe
                              title="map"
                              width="100%"
                              height="100%"
                              frameBorder={0}
                              src={`https://maps.google.com/maps?q=${needDetails?.lat},${needDetails?.lon}&t=&z=15&ie=UTF8&iwloc=&output=embed`}
                            />
                          </Box>
                        </Grid>
                      )}

                      {needDetails?.documents?.length !== 0 && (
                        <Grid item md={12}>
                          <Typography color="primary.main">
                            Supporting Documents
                          </Typography>
                          <List dense={true}>
                            {needDetails?.documents?.map((document, index) => {
                              let documentName = document?.split?.("/").pop();
                              return (
                                <ListItem
                                  sx={{
                                    border: `1px solid ${purpleLight}`,
                                    borderRadius: 4,
                                    mb: 1,
                                    pr: 0,
                                  }}
                                >
                                  <img src={AttachmentIcon} />

                                  <ListItemText
                                    primary={"Document"}
                                    primaryTypographyProps={{ ml: 1 }}
                                  ></ListItemText>
                                  <ListItemIcon>
                                    <a
                                      href={document}
                                      target="_blank"
                                      download={documentName}
                                      rel="noreferrer"
                                    >
                                      <IconButton color="primary">
                                        <DownloadOutlined />
                                      </IconButton>
                                    </a>
                                  </ListItemIcon>
                                </ListItem>
                              );
                            })}
                          </List>
                        </Grid>
                      )}
                      {needDetails?.feedback || needDetails?.rating ? (
                        <>
                          <Grid item md={6}>
                            {listItem({
                              title: "User feedback",
                              value: needDetails?.feedback,
                            })}
                          </Grid>
                          <Grid item md={6}>
                            {listItem({
                              title: "User rating",
                              value: (
                                <Rating value={needDetails?.rating} readOnly />
                              ),
                            })}
                          </Grid>
                        </>
                      ) : null}
                      <Grid item md={12}>
                        <Box display="flex" justifyContent="space-between">
                          <Box>
                            <StatusMenu
                              status={needDetails?.status || ""}
                              updatedStatus={needStatus}
                              onStatusChange={(status) => {
                                setNeedStatus(status);
                                setNeedStatusChanged(true);
                              }}
                            />
                          </Box>

                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="end"
                          >
                            <Typography>Generate Report:</Typography>
                            <IconButton onClick={downloadExcel}>
                              <img src={XLSIcon} alt="" />
                            </IconButton>
                            <IconButton onClick={downloadPdf}>
                              <img src={PDFIcon} alt="" />
                            </IconButton>
                          </Box>
                        </Box>
                      </Grid>

                      {needDetails?.status !== 'Closed' && (editInternalNote ||
                        needDetails?.systemNotes === "" ||
                        needDetails?.systemNotes === null) ? (
                        <Grid item md={12}>
                          <TextField
                            disabled={!hasPermission("Needs", "Update")}
                            fullWidth
                            value={internalNotes}
                            rows={4}
                            multiline={true}
                            placeholder="Enter internal notes if any"
                            label="Internal note"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={(e) => {
                              setInternalNotes(e.target.value);
                            }}
                            InputProps={{
                              endAdornment: (
                                <Button
                                  variant="text"
                                  onClick={() => {
                                    setEditInternalNote(false);
                                    setInternalNotes(
                                      needDetails?.systemNotes || ""
                                    );
                                  }}
                                  sx={{
                                    position: "absolute",
                                    bottom: 0,
                                    right: 0,
                                  }}
                                >
                                  Clear
                                </Button>
                              ),
                            }}
                          />
                        </Grid>
                      ) : (
                        (needDetails?.status !== 'Closed' || needDetails?.systemNotes) &&
                        <Grid item md={12}>
                          <Box>
                            <Box display="flex" flexDirection={"row"}>
                              <Typography color="primary.main">
                                {"Internal Note"}
                              </Typography>

                              <EditOutlined
                                sx={{
                                  height: 16,
                                  width: 16,
                                  ml: 1,
                                  cursor: hasPermission("Needs", "Update")
                                    ? "pointer"
                                    : "auto",
                                }}
                                onClick={() =>
                                  hasPermission("Needs", "Update") &&
                                  setEditInternalNote(true)
                                }
                              />
                            </Box>
                            <Typography>{needDetails?.systemNotes}</Typography>
                          </Box>
                        </Grid>
                      )}
                      {needDetails?.systemComments !== "" && (
                        <Grid item md={12}>
                          <Box>
                            <Typography color="primary.main">
                              {"Admin Feedback"}
                            </Typography>
                            <Typography>
                              {needDetails?.systemComments}
                            </Typography>
                          </Box>
                        </Grid>
                      )}
                      <Grid item md={12}>
                        <Box display="flex" justifyContent="space-between">
                          <Button
                            variant="outlined"
                            sx={{
                              pl: 2,
                              pr: 2,
                              textTransform: "capitalize",
                              fontWeight: 600,
                              minWidth: 200,
                            }}
                            onClick={() => navigate(-1)}
                          >
                            Back To Needs
                          </Button>
                          <Button
                            variant="contained"
                            sx={{
                              pl: 4,
                              pr: 4,
                              textTransform: "capitalize",
                              fontWeight: 600,
                              minWidth: 200,
                            }}
                            disabled={
                              needDetails?.status === 'Closed' ? true : internalNotes !== ""
                                ? false
                                : !isNeedStatusChanged
                            }
                            onClick={() => {
                              updateNeedStatus()
                            }}
                          >
                            Save
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <UpdateNeedStatus
        open={closeNeedStatusDialog}
        onDialogClose={() => {
          setCloseNeedStatusDialog(false);
        }}
        updateNeedStatusToClosed={updateNeedStatusToClosed}
      />
    </MainLayout>
  );
}
