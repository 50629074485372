import { Close } from "@mui/icons-material";
import {
    Autocomplete,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Dialog,
    Divider,
    IconButton,
    TextField,
    Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React, { useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { MasterSchemeInterface } from "../../../schemes/family_enquiries/family_enquiry_list";

interface FilterChoicesForChoiceInterface {
    openchoicesModel: boolean;
    setOpenChoicesModal: (val: boolean) => void;
    startDate: string;
    setStartDate: (value: string) => void;
    endDate: string;
    setEndDate: (value: string) => void;
    resetFilter: () => void;
    validFrom: string;
    setValidFrom: (value: string) => void;
    validTo: string;
    setValidTo: (value: string) => void;
}

export default function FilterChoicesForChoice({
    openchoicesModel,
    setOpenChoicesModal,
    setStartDate,
    endDate,
    setEndDate,
    resetFilter,
    validFrom,
    validTo,
    setValidFrom,
    setValidTo

}: FilterChoicesForChoiceInterface) {
    const [_endDate, _setEndDate] = useState<string>(endDate);
    const [_startDate, _setStartDate] = useState<string>(endDate);
    const [_validTo, _setValidTo] = useState("");
    const [_validFrom, _setValidFrom] = useState("")

    return (
        <Dialog open={openchoicesModel}>
            <Box sx={{ width: 400 }}>
                <Card sx={{ px: 2 }}>
                    <CardHeader
                        title="Filter Choices"
                        titleTypographyProps={{ variant: "body1", fontWeight: 600 }}
                        action={
                            <IconButton onClick={() => setOpenChoicesModal(false)}>
                                <Close sx={{ color: "#323232" }} />
                            </IconButton>
                        }
                    >
                        <Typography></Typography>
                    </CardHeader>
                    <CardHeader
                        title="Filter By"
                        titleTypographyProps={{ variant: "body2", fontWeight: 600 }}
                        action={
                            <Button
                                variant="text"
                                sx={{ p: 0 }}
                                onClick={() => {
                                    resetFilter();
                                    _setEndDate("");
                                    _setStartDate("");
                                    _setValidFrom("");
                                    _setValidTo("");
                                    setOpenChoicesModal(false);
                                }}
                            >
                                Reset
                            </Button>
                        }
                    >
                        <Typography></Typography>
                    </CardHeader>
                    <CardContent sx={{ maxHeight: 400, overflow: "auto scroll" }}>
                        {_dateRange({
                            title: "Date Range",
                            setEndDate: _setEndDate,
                            setStartDate: _setStartDate,
                            startDate: _startDate,
                            endDate: _endDate,
                        })}
                        {_dateRange({
                            title: "Survey Valid Range",
                            setEndDate: _setValidTo,
                            setStartDate: _setValidFrom,
                            startDate: _validFrom,
                            endDate: _validTo,
                            range_one_title: "Valid From",
                            range_two_title: "Valid To",
                            disableFutureDate: false
                        })}
                        <Box mt={2} display="flex" justifyContent="space-between">
                            <Button onClick={() => setOpenChoicesModal(false)}>Close</Button>
                            <Button
                                sx={{ minWidth: 200 }}
                                variant="contained"
                                onClick={() => {
                                    setStartDate(_startDate);
                                    setEndDate(_endDate);
                                    setValidFrom(_validFrom);
                                    setValidTo(_validTo);
                                    setOpenChoicesModal(false);
                                }}
                            >
                                Apply Filters
                            </Button>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </Dialog>
    );

    function _dropdownOption({
        title,
        placeholder,
        options,
        selectedId,
        onSelect,
    }: {
        title: string;
        placeholder: string;
        options: { id: number; value: string }[];
        selectedId: number | null;
        onSelect: (id: number | null) => void;
    }) {
        return (
            <Box pt={2} pb={1}>
                <Typography fontSize={16} fontWeight="600">
                    {title}
                </Typography>
                <Box pt={1} pb={2} display="flex" flexDirection="row">
                    <Autocomplete
                        fullWidth
                        // multiple
                        value={options?.find((option) => selectedId === option?.id)}
                        onChange={(e, value) => {
                            onSelect(value?.id || null);
                        }}
                        options={options}
                        getOptionLabel={(option) => option?.value}
                        // defaultValue={[{ title: "The Godfather", year: 1972 }]}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label={placeholder}
                                placeholder={placeholder}
                            />
                        )}
                    />
                </Box>
                <Divider />
            </Box>
        );
    }

    function _dateRange({
        title,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        range_one_title,
        range_two_title,
        disableFutureDate = true
    }: {
        title: string;
        startDate: string;
        setStartDate: (value: string) => void;
        endDate: string;
        setEndDate: (value: string) => void;
        range_one_title?: string,
        range_two_title?: string,
        disableFutureDate?: boolean
    }) {
        return (
            <Box pt={0} pb={1}>
                <Typography fontSize={16} fontWeight="600">
                    {title}
                </Typography>
                <Box pt={1} pb={2} display="flex" flexDirection={"column"}>
                    <DatePicker
                        label={range_one_title ? range_one_title : "Start Date"}
                        format="DD-MM-YYYY"
                        disableFuture
                        value={startDate ? dayjs(startDate, "YYYY-MM-DD") : null}
                        onChange={(value: Dayjs | null) => {
                            setStartDate(value?.format("YYYY-MM-DD") || "");
                        }}
                        slotProps={{ desktopPaper: { elevation: 3 } }}
                        sx={{
                            "& .MuiSvgIcon-root": {
                                fill: "#990099", // Replace with your desired color
                            },
                        }}
                    />
                    <Box p={1}></Box>
                    <DatePicker
                        label={range_two_title ? range_two_title : "End Date"}
                        format="DD-MM-YYYY"
                        disableFuture={disableFutureDate}
                        value={endDate ? dayjs(endDate, "YYYY-MM-DD") : null}
                        onChange={(value: Dayjs | null) => {
                            setEndDate(value?.format("YYYY-MM-DD") || "");
                        }}
                        slotProps={{ desktopPaper: { elevation: 3 } }}
                        sx={{
                            "& .MuiSvgIcon-root": {
                                fill: "#990099", // Replace with your desired color
                            },
                        }}
                    />
                </Box>
            </Box>
        );
    }
}
