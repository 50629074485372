import { ArrowBack } from "@mui/icons-material";
import { Box, Card, IconButton, Tab, Tabs, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useMemo } from "react";
import { SubmitHandler } from "react-hook-form";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { Serializer, surveyLocalization, } from "survey-core";
import { SurveyCreator } from "survey-creator-react";
import MainLayout from "../../../layouts/main/main_layout";
import { useFeedback } from "../../../providers/feedback/feeedback";
import {
  getChoice,
  getChoiceQuestions,
  getMasterData,
  updateChoiceEligibility,
  updateChoiceQuestions,
} from "../../../services/choices/choices_services";
import ChoiceQuestionnaire from "../components/choice_quitionnaire/choice_questionnaire";
import EligibilityCriteria from "../components/eligibility_criteria/eligibility_criteria";
import { ChoiceFormInput } from "../model/choice_form";
import { ChoiceDetails } from "../model/choices_Details";

Serializer.getProperty("file", "storeDataAsText").visible = false;
Serializer.getProperty("file", "storeDataAsText").defaultValue = false;
Serializer.getProperty("file", "showCommentArea").visible = false;
Serializer.getProperty("file", "allowMultiple").visible = false;
Serializer.getProperty("file", "allowMultiple").defaultValue = false;
Serializer.getProperty("file", "allowCameraAccess").defaultValue = false;

console.log(Serializer.getAllPropertiesByName("file"));

interface ViewChoicesInterface {
  viewOnly?: boolean;
  editChoice?: boolean;
}

interface EligibilityType {
  genders: string[];
  fromAge: number;
  toAge: number;
  stateIds: number[];
  districtIds: number[] | null;
  residenceIds: number[];
  casteIds: number[];
  workingStatusIds: number[];
  occupationIds: number[];
  maritalStatusIds: number[];
  personalIncomeIds: number[];
  familyIncomeIds: number[];
  educationIds: number[];
  familyTypeIds: number[];
  houseOwnershipIds: number[];
  durableOwnershipIds: number[] | null;
  areasOfInterestIds: number[];
  differentlyAbled: boolean | null;
  minority: boolean | null;
  bplCategory: boolean | null;
  student: boolean | null;
  vendorEmailId?: string; // Add this line to include 'vendorEmailId' as an optional property
  nccsGrades: string[] | null;
}

export default function ViewChoices({
  viewOnly,
  editChoice,
}: ViewChoicesInterface) {
  const { id } = useParams();
  const [currentTab, setCurrentTab] = React.useState(0);
  const navigate = useNavigate();
  const { showSnackbar } = useFeedback();

  const handleChange = (event: React.SyntheticEvent, currentTabVal: number) => {
    setCurrentTab(currentTabVal);
  };

  const { data: choicesMasterData, isLoading } = useQuery(
    ["choiceMasterData"],
    async () => {
      let response = await getMasterData();
      return response?.data?.data || [];
    }
  );

  const { data: choiceDetails } = useQuery<ChoiceDetails>(
    ["choice-details", viewOnly],
    async () => {
      let response = await getChoice(+id!);
      return response?.data?.data || [];
    }
  );
  const { data: choiceQuestions } = useQuery<any>(
    ["choice-questions", viewOnly],
    async () => {
      let response = await getChoiceQuestions(+id!);
      return response?.data?.data || [];
    }
  );

  const creator = useMemo(
    () =>
      new SurveyCreator({
        showLogicTab: !viewOnly,
        isAutoSave: true,
        haveCommercialLicense: true,
        showTranslationTab: !viewOnly,
        showJSONEditorTab: !viewOnly,
        showDesignerTab: !viewOnly,
        questionTypes: [
          "boolean",
          "checkbox",
          "comment",
          "dropdown",
          "tagbox",
          "matrix",
          "matrixdropdown",
          "matrixdynamic",
          "multipletext",
          "radiogroup",
          "rating",
          "ranking",
          "text",
          "imagepicker",
          "html",
          "file"
        ],
      }),
    []
  );


  surveyLocalization.supportedLocales = [
    "en",
    "hi",
    "kn",
    "te",
    "ta",
    "bn",
    "ml",
    "mr",
    "gu",
    "pa",
    "or",
    "as",
  ];

  useEffect(() => {
    Serializer.addProperty("question", {
      name: "isImportant:boolean",
      default: false,
      category: "general",
      isSerializable: true,
    });

    creator.onQuestionAdded.add((sender, options) => {
      const question = options.question;
      if (question) {
        question.isImportant = question.isImportant ?? false;
      }
    });


  }, [creator]);



  creator.onPropertyValueChanging.add((sender, options) => {
    console.log(sender, options);
  });
  creator.saveSurveyFunc = (saveNo: any, callback: any) => {
    window.localStorage.setItem("survey-json", creator.text);
    callback(saveNo, true);
  };

  // creator.JSON = choiceQuestions?.details || {};
  useMemo(
    () => (creator.JSON = choiceQuestions?.details || {}),
    [choiceQuestions]
  );

  const onSubmit: SubmitHandler<ChoiceFormInput> = (data) => {
    let validFrom = data?.surveyValidTo
      ? dayjs(data?.surveyValidFrom, "YYYY-MM-DD")
        .format("MM-DD-YYYY")
        .toString()
      : null;
    let validTo = data?.surveyValidTo
      ? dayjs(data?.surveyValidTo, "YYYY-MM-DD").format("MM-DD-YYYY").toString()
      : null;
    let expiry = {
      validFrom: validFrom,
      validTo: validTo,
    };
    let eligibility: EligibilityType = {
      genders: data?.eligibleGender?.map((item) => item),
      fromAge: data?.fromAge,
      toAge: data?.toAge,
      stateIds: data?.eligibleStates?.map((item) => item),
      districtIds:
        data?.eligibleDsitricts?.length === 0
          ? null
          : data?.eligibleDsitricts?.map((item) => item),
      residenceIds: data?.areaOfResidence,
      casteIds: data?.eligibleCaste,
      workingStatusIds: data?.eligibleEmploymentStatus?.map((item) => item),
      occupationIds: data?.eligibleOccupation?.map((item) => item),
      maritalStatusIds: data?.eligibleMaritalStatus?.map((item) => item),
      personalIncomeIds: data?.personalIncome?.map((item) => item),
      familyIncomeIds: data?.familyIncome?.map((item) => item),
      educationIds: data?.educationQualification?.map((item) => item),
      familyTypeIds: data?.familyType?.map((item) => item),
      houseOwnershipIds: data?.ownershipHouse?.map((item) => item),
      durableOwnershipIds:
        data?.durableOwnership?.length === 0 ? null : data?.durableOwnership,
      areasOfInterestIds: data?.areasOfInterest,
      differentlyAbled:
        data?.differentlyEbledOnly === undefined
          ? null
          : data?.differentlyEbledOnly,
      minority: data?.minorityOnly === undefined ? null : data?.minorityOnly,
      bplCategory: data?.bplCardOnly === undefined ? null : data?.bplCardOnly,
      student: data?.studentsOnly === undefined ? null : data?.studentsOnly,
      nccsGrades: data?.nccsGrades?.length === 0 ? null : data?.nccsGrades,
    };
    if (data?.vendorsEmailId) {
      eligibility["vendorEmailId"] = data?.vendorsEmailId;
    }

    let payload = { expiry: expiry, eligibility: eligibility };

    if (
      (data?.nccsGrades?.length === 0 || data?.nccsGrades === undefined) &&
      (data?.educationQualification?.length === 0 ||
        data?.educationQualification === undefined) &&
      (data?.durableOwnership?.length === 0 ||
        data?.durableOwnership === undefined)
    ) {
      showSnackbar(
        "Please select either NCCS grades or Durable Ownership and Education Qualification"
      );
    } else if (
      (data?.educationQualification?.length === 0 ||
        data?.educationQualification === undefined) &&
      (data?.nccsGrades === undefined || data?.nccsGrades?.length === 0)
    ) {
      showSnackbar("Please select Education Qualification");
    } else if (
      (data?.durableOwnership?.length === 0 ||
        data?.durableOwnership === undefined) &&
      (data?.nccsGrades === undefined || data?.nccsGrades?.length === 0)
    ) {
      showSnackbar("Please select Durable Ownership");
    } else {
      updateChoiceEligibility(payload, +id!)
        .then((res) => {
          showSnackbar("Details updated successfully");
        })
        .catch((e) => {
          if (e?.response?.error?.status === 419) {
            showSnackbar("Please make modifications to see changes reflected");
          } else {
            showSnackbar("Something went wrong");
          }
        });
    }
  };

  function submitQuestions() {
    let questionObject = creator.JSON;
    let questions = {
      title: questionObject?.title,
      description: questionObject?.description,
      logoPosition: questionObject?.logoPosition,
      pages: questionObject.pages,
    };
    let payload = {
      questions: questions,
    };

    let isTranslationExistsData =
      questions?.pages?.find((page: any) => {
        return page.elements?.find((element: any) => {
          return typeof element?.title == "object" && !element?.title?.default;
        });
      }) || [];

    let isTitleExists =
      typeof questions?.title === "object"
        ? !!questions?.title.default
        : !!questions?.title;
    let isDescriptionExists =
      typeof questions?.description === "object"
        ? !!questions?.description?.default
        : !!questions?.description;

    if (!isTitleExists) {
      showSnackbar("Please enter title for the survey");
    } else if (!isDescriptionExists) {
      showSnackbar("Please enter description for the survey");
    } else if (isTranslationExistsData?.length !== 0) {
      showSnackbar(
        `Please enter default translation for ${isTranslationExistsData?.elements?.[0]?.name}`
      );
    } else {
      updateChoiceQuestions(payload, +id!)
        .then((res) => {
          if (res.status === 200) {
            showSnackbar("Details updated succesfully");
          } else {
            showSnackbar("Something went wrong");
          }
        })
        .catch((e) => {
          showSnackbar("Something went wrong");
        });
    }
  }

  function renderItemBasedOnTheTabSelection() {
    switch (currentTab) {
      case 0:
        return (
          <EligibilityCriteria
            choicesMasterData={choicesMasterData}
            onSubmit={onSubmit}
            choiceDetails={choiceDetails || null}
            viewOnly={viewOnly}
            editChoice={editChoice}
          />
        );
      case 1:
        return (
          <ChoiceQuestionnaire
            surveyCreator={creator}
            submitSurvey={submitQuestions}
            onSubmit={submitQuestions}
            viewOnly={viewOnly}
            editChoice={editChoice}
            isSurveyTaken={false}
          />
        );
      default:
        break;
    }
  }

  return (
    <MainLayout>
      <Box display={"flex"} mt={4}>
        <Card
          sx={{
            display: "flex",
            width: "100%",
            minHeight: 600,
            mb: 2,
            minWidth: 1200,
            px: 2,
            pb: 3,
          }}
        >
          <Box display="flex" flexDirection={"column"} sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider", py: 2, pb: 0 }}>
              <Box flexDirection="row" display="flex" alignItems="center">
                <IconButton onClick={() => navigate(-1)}>
                  <ArrowBack />
                </IconButton>
                <Typography variant="h6" fontWeight="600">
                  {viewOnly ? "View new choice" : "Edit new choice"}
                </Typography>
              </Box>
              <Tabs
                value={currentTab}
                aria-label="basic tabs example"
                onChange={handleChange}
                sx={{
                  "MuiTabs-root css-112qdbf-MuiTabs-root": {
                    borderColor: "#F5E6F5",
                  },
                }}
              >
                <Tab
                  label="Eligibility Criteria"
                  sx={{
                    textTransform: "capitalize",
                    color: "#1B001B",
                  }}
                />
                <Tab
                  label="Questionnaire"
                  sx={{ textTransform: "capitalize", color: "#1B001B" }}
                />
              </Tabs>
            </Box>
            {renderItemBasedOnTheTabSelection()}
          </Box>
        </Card>
      </Box>
    </MainLayout>
  );
}
