import React, { useEffect, useState } from "react";
import MainLayout from "../../../layouts/main/main_layout";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import XLSIcon from "../../../assets/ph_file-xls.svg";
import { ArrowBack, Star } from "@mui/icons-material";
import Loading from "../../../components/loading/loading";
import { ServiceDetail } from "../model/service_details_model";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteService,
  downloadServiceDetails,
  getServiceDetails,
  updateService,
} from "../../../services/services/services";
import { useQuery } from "react-query";
import StatusMenu from "../components/status_menu/status_menu";
import UpdateServiceStatusToReject from "../components/update_service_status/update_service_status_popup";
import UpdateServiceStatusToDelete from "../components/update_service_status/update_service_status_popup";
import { useFeedback } from "../../../providers/feedback/feeedback";
import { queryClient } from "../../../providers/query/query";
import { downloadFromBlob } from "../../../utils/utils";
import useUser from "../../../providers/user/user_provider";

export default function ServiceDetails() {
  const [serviceStatus, setServiceStatus] = useState<string | null>(null);
  const [closeRejectServiceStatusDialog, setCloseRejectServiceStatusDialog] =
    useState<boolean>(false);
  const [closeDeleteServiceStatusDialog, setCloseDeleteServiceStatusDialog] =
    useState<boolean>(false);

  let { id } = useParams();
  const navigate = useNavigate();
  const { showSnackbar } = useFeedback();
  const { hasPermission } = useUser();

  const { data: serviceDetails, isLoading } = useQuery<ServiceDetail>(
    ["service-details", id],
    async () => {
      let data = await getServiceDetails({ serviceId: +id! });
      return data?.data?.data?.data;
    }
  );

  function updateServiceStatus(comments: string, statusId: number) {
    updateService({
      comment: comments,
      statusId: statusId,
      serviceId: +id!,
    })
      .then((res) => {
        if (res.status === 200) {
          showSnackbar("Service status updated successfully");
          queryClient.invalidateQueries({ queryKey: ["service-details"] });
        }
      })
      .catch((e) => {
        showSnackbar("Something went wrong");
      });
  }

  function deleteServiceApiCall(comments: string) {
    deleteService({ serviceId: +id!, comment: comments })
      .then((res) => {
        if (res.status === 200) {
          showSnackbar("Service removed successfully");
          navigate(-1);
        } else {
          showSnackbar("Something went wrong");
        }
      })
      .catch((e) => {
        showSnackbar("Something went wrong");
      });
  }

  function updateServiceStatusApiCall(comments: string, statusId: number) {
    updateService({
      comment: comments,
      statusId: statusId,
      serviceId: +id!,
    }).then((res) => {
      if (res.status === 200) {
        showSnackbar("Service status updated successfully");
        queryClient.invalidateQueries({ queryKey: ["service-details"] });
      }
    });
  }

  function approveService() {
    updateService({
      comment: "",
      statusId: 3,
      serviceId: +id!,
    }).then((res) => {
      if (res.status === 200) {
        showSnackbar("Service status updated successfully");
        queryClient.invalidateQueries({ queryKey: ["service-details"] });
      }
    });
  }

  function downloadExcel() {
    downloadServiceDetails(id!, "excel").then((response) => {
      downloadFromBlob(response, new Date().valueOf() + ".xlsx");
    });
  }

  function listItem({
    title,
    value,
  }: {
    title: string;
    value: any;
  }): JSX.Element {
    return (
      <Box>
        <Typography color="primary.main">{title}</Typography>
        <Typography>{value || "-"}</Typography>
      </Box>
    );
  }
  function loading() {
    return (
      <Box
        height={500}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Loading />
      </Box>
    );
  }

  useEffect(() => {
    setServiceStatus(serviceDetails?.status!);
  }, [serviceDetails]);

  return (
    <MainLayout>
      <Grid container spacing={3}>
        <Grid item md={8} lg={7} sm={12} xs={12}>
          <Card sx={{ width: "100%", borderRadius: 2, m: 1 }} elevation={1}>
            <CardContent>
              <Box display="flex" flexDirection="row">
                <Box width={200} display="flex" alignItems="center">
                  <IconButton onClick={() => navigate(-1)}>
                    <ArrowBack></ArrowBack>
                  </IconButton>
                  <Typography variant="h6" fontWeight="600" paddingLeft={1}>
                    Services Details
                  </Typography>
                </Box>
                <Box
                  flex={1}
                  display="flex"
                  alignItems="center"
                  justifyContent="end"
                ></Box>
              </Box>
              <Box mt={2} mb={2}>
                <Divider />
              </Box>
              <Box m={2}>
                {isLoading ? (
                  loading()
                ) : (
                  <Box overflow="auto" flex={1}>
                    <Grid container spacing={3}>
                      <Grid item md={6}>
                        {listItem({
                          title: "Sl. No.",
                          value: +id!,
                        })}
                      </Grid>
                      <Grid item md={6}>
                        {listItem({
                          title: "Name of Service / Person",
                          value: serviceDetails?.name,
                        })}
                      </Grid>
                      <Grid item md={6}>
                        {listItem({
                          title: "Service description",
                          value: serviceDetails?.description,
                        })}
                      </Grid>
                      <Grid item md={6}>
                        {listItem({
                          title: "Service Category",
                          value: serviceDetails?.category,
                        })}
                      </Grid>
                      <Grid item md={6}>
                        {listItem({
                          title: "State",
                          value: serviceDetails?.state,
                        })}
                      </Grid>
                      <Grid item md={6}>
                        {listItem({
                          title: "City",
                          value: serviceDetails?.city,
                        })}
                      </Grid>

                      <Grid item md={6}>
                        {listItem({
                          title: "District",
                          value: serviceDetails?.district,
                        })}
                      </Grid>

                      <Grid item md={6}>
                        {listItem({
                          title: "Service Contact Number",
                          value: `+91 ${serviceDetails?.serviceMobileNo}`,
                        })}
                      </Grid>
                      <Grid item md={6}>
                        {listItem({
                          title: "Service Email ID",
                          value: serviceDetails?.serviceEmailId,
                        })}
                      </Grid>
                      <Grid item md={6}>
                        {listItem({
                          title: "Service Website URL",
                          value: serviceDetails?.websiteURL,
                        })}
                      </Grid>
                      <Grid item md={6}>
                        {listItem({
                          title: "Whatsapp Number",
                          value: `+91 ${serviceDetails?.mobileNumber}`,
                        })}
                      </Grid>
                      <Grid item md={6}>
                        <Box>
                          <StatusMenu
                            status={serviceDetails?.status || ""}
                            updatedStatus={serviceStatus}
                            onStatusChange={(status) => {
                              if (status !== serviceStatus) {
                                setServiceStatus(status);
                                if (status === "Modified") {
                                  updateServiceStatusApiCall("", 5);
                                } else {
                                  updateServiceStatusApiCall("", 2);
                                }
                              }
                            }}
                          />
                        </Box>
                      </Grid>
                      {serviceDetails?.reviewCount ||
                        serviceDetails?.overallRating ? (
                        <Grid item md={6}>
                          <Box>
                            <Typography color="primary.main">
                              {"Review and Ratings"}
                            </Typography>
                            <Box
                              display="flex"
                              flexDirection={"row"}
                              sx={{
                                backgroundColor: "#F5E6F5",
                                width: "fit-content",
                                borderRadius: 3,
                              }}
                              px={2}
                              py={1}
                            >
                              <Star
                                sx={{ color: "#F3B820", height: 17, width: 17 }}
                              />
                              <Typography variant="caption" fontWeight="600">
                                {serviceDetails?.overallRating} |
                              </Typography>
                              <Typography variant="caption" ml={0.5}>
                                {serviceDetails?.reviewCount}
                                {" reviews"}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      ) : null}
                    </Grid>
                    {serviceDetails?.systemComments && (
                      <>
                        <Box mt={2} mb={2}>
                          <Divider />
                        </Box>
                        <Grid item md={12}>
                          {listItem({
                            title: "Reason for Rejection",
                            value: serviceDetails?.systemComments,
                          })}
                        </Grid>
                      </>
                    )}

                    {serviceDetails?.documents.length !== 0 && (
                      <Box mt={3}>
                        <Typography variant="body1" fontWeight="600">
                          Uploaded images
                        </Typography>
                        <Box
                          display="flex"
                          flexDirection={"row"}
                          overflow={"auto"}
                        >
                          {serviceDetails?.documents?.map((doc) => {
                            return (
                              <Box mr={2} mt={2} borderRadius={3}>
                                <img
                                  alt=""
                                  src={doc}
                                  height={150}
                                  width={260}
                                  style={{ borderRadius: "8px" }}
                                />
                              </Box>
                            );
                          })}
                        </Box>
                      </Box>
                    )}
                    <Grid container>
                      {serviceDetails?.documents.map((doc) => {
                        return <Grid item md={3}></Grid>;
                      })}
                    </Grid>
                    <Box mt={2} mb={2}>
                      <Divider />
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Typography>Generate Report:</Typography>
                      <IconButton sx={{ ml: 2 }} onClick={downloadExcel}>
                        <img src={XLSIcon} alt="" />
                      </IconButton>
                    </Box>
                    <Grid item md={12}>
                      <Box display="flex" justifyContent="space-between">
                        <Button
                          variant="outlined"
                          sx={{
                            pl: 2,
                            pr: 2,
                            textTransform: "capitalize",
                            fontWeight: 600,
                          }}
                          onClick={() => navigate(-1)}
                        >
                          Back To Services
                        </Button>
                        <Box>
                          {serviceDetails?.status === "Rejected" ||
                            serviceDetails?.status === "Approved" ? null : (
                            <Button
                              disabled={!hasPermission("Services", "Update")}
                              variant="outlined"
                              sx={{
                                pl: 4,
                                pr: 4,
                                textTransform: "capitalize",
                                fontWeight: 600,
                              }}
                              onClick={() => {
                                setCloseRejectServiceStatusDialog(true);
                              }}
                            >
                              Reject Listing
                            </Button>
                          )}
                          {serviceDetails?.status === "Rejected" ? (
                            <Button
                              onClick={() => null}
                              variant="contained"
                              sx={{
                                minWidth: 150,
                                opacity: 0.5,
                                backgroundColor: "#D14C2E",
                              }}
                            >
                              {"Rejected"}
                            </Button>
                          ) : (
                            <Button
                              disabled={
                                serviceDetails?.status === "Approved"
                                  ? !hasPermission("Services", "Delete")
                                  : !hasPermission("Services", "Update")
                              }
                              variant="contained"
                              sx={{
                                pl: 4,
                                pr: 4,
                                fontWeight: 600,
                                ml: 2,
                                backgroundColor:
                                  serviceDetails?.status === "Modified" ||
                                    serviceDetails?.status === "In Progress" ||
                                    serviceDetails?.status === "Pending"
                                    ? "#1E6446"
                                    : "#91278F",
                              }}
                              onClick={() => {
                                if (serviceDetails?.status === "Approved") {
                                  setCloseDeleteServiceStatusDialog(true);
                                } else {
                                  approveService();
                                }
                              }}
                            >
                              {serviceDetails?.status === "Approved"
                                ? "Delete Listing"
                                : "Approve Listing"}
                            </Button>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                  </Box>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {closeRejectServiceStatusDialog && (
        <UpdateServiceStatusToReject
          open={closeRejectServiceStatusDialog}
          onDialogClose={() => {
            setCloseRejectServiceStatusDialog(false);
          }}
          updateServiceStatus={(comments) => {
            updateServiceStatus(comments, 4);
            setCloseRejectServiceStatusDialog(false);
          }}
          title={"Reject Service"}
          subtitle={"Reason for rejection:"}
        />
      )}
      {closeDeleteServiceStatusDialog && (
        <UpdateServiceStatusToDelete
          open={closeDeleteServiceStatusDialog}
          onDialogClose={() => {
            setCloseDeleteServiceStatusDialog(false);
          }}
          updateServiceStatus={(comments) => {
            deleteServiceApiCall(comments);
            setCloseDeleteServiceStatusDialog(false);
          }}
          title={"Delete Service"}
          subtitle={"Reason for Deletion:"}
        />
      )}
    </MainLayout>
  );
}
