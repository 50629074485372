import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemText,
  ListSubheader,
  TablePagination,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Loading from "../../../../components/loading/loading";
import { DeleteOutline, Edit, Search, Visibility } from "@mui/icons-material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import { SurveyList } from "../../model/surveyList";
import {
  removeSurveyor,
  serveyorList,
} from "../../../../services/surveyor/surveyor";
import { useFeedback } from "../../../../providers/feedback/feeedback";
import { queryClient } from "../../../../providers/query/query";
import DeleteSuryevorPopup from "../delete_surveyor_popup/delete_surveyor_popup";
import SurveyPopup from "../../../choices/components/choice_popup/choice_popup";
import useUser from "../../../../providers/user/user_provider";
import styled from "@emotion/styled";


const TextField = styled(MuiTextField)(({ theme }) => {
  return {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "transparent", // Border color when focused
      },
      "&:hover fieldset": {
        borderColor: "transparent", // Border color on hover
      },
      "& fieldset": {
        borderColor: "transparent", // Default border color
      },
    },
  };
});

export default function ChoicesListing() {
  const [searchParams, setUrlParam] = useSearchParams();
  const [choicesList, setChoicesList] = useState([]);
  const [selectedSurveyor, setSelectedSurveyor] = useState<SurveyList | null>(
    null
  );
  const [openDeletePopup, setOpenDeletePopup] = useState(false);

  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(
    // searchParams.has("page") ? +searchParams?.get("page")! :
    0
  );

  const navigate = useNavigate();
  const { showSnackbar } = useFeedback();
  const { hasPermission } = useUser();

  // useEffect(() => {
  //   setUrlParam({
  //     page: page ? page?.toString() : "0",
  //   });
  // }, [page]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const { data: surveyorsListData, isLoading } = useQuery(
    ["surveyorListing", page, searchValue],
    async () => {
      let response = await serveyorList({
        page: page + 1,
        size: 8,
        keyword: searchValue,
      });
      return response?.data?.data || [];
    }
  );

  function deleteSurveyor() {
    removeSurveyor({ id: selectedSurveyor?.id! })
      .then((res) => {
        showSnackbar("Surveyor deleted successfully");
        queryClient.invalidateQueries({ queryKey: ["surveyorListing"] });
      })
      .catch((e) => showSnackbar("Something went wrong"));
    setOpenDeletePopup(false);
  }

  function searchComponent() {
    return (
      <Box mt={2}>
        <TextField
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          size="small"
          fullWidth
          sx={{
            backgroundColor: "#F5E6F5",
            borderRadius: 3,
            borderColor: "transparent",
          }}
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search sx={{ color: "text.primary" }} />
              </InputAdornment>
            ),
          }}
        />
      </Box>
    );
  }

  return (
    <>
      <CardContent sx={{ display: "flex", flexGrow: 1, height: "100%" }}>
        <Grid container>
          <Grid
            display={"flex"}
            flexDirection={"column"}
            py={1}
            pl={1}
            width="100%"
            item
            md={12}
            sm={12}
            xs={12}
            lg={12}
            xl={12}
          >

            {searchComponent()}

            {isLoading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                <Loading />
              </Box>
            ) : surveyorsListData?.list?.length !== 0 ? (
              <Box display={"flex"} flexDirection={"column"}>
                {surveyorsListData?.list?.map(
                  (choice: SurveyList, index: number) => {
                    return (
                      <>
                        <ListItem
                          sx={{
                            margin: 0,
                            padding: 0,
                            my: 2,
                          }}
                        >
                          <ListItemText
                            primary={choice?.name}
                            secondary={
                              <Box display="flex" flexDirection="row">
                                <Typography
                                  variant="body2"
                                  fontWeight={"600"}
                                >{`${choice.choicesAssigned} Choices mapped`}</Typography>
                                <Typography variant="body2">
                                  , {choice.state} - {choice?.district} -
                                  {choice?.city}
                                </Typography>
                              </Box>
                            }
                            primaryTypographyProps={{
                              color: "#1B001B",
                            }}
                            secondaryTypographyProps={{
                              color: "#91278F",
                            }}
                          />
                          <ListSubheader sx={{ margin: 0, padding: 0 }}>
                            <IconButton
                              onClick={() => {
                                navigate(
                                  `/surveyor/viewSurveyorDetails/${choice.id}`
                                );
                              }}
                              sx={{
                                backgroundColor: "#E9F0ED",
                                borderRadius: 4,
                                color: "#1B001B",
                              }}
                            >
                              <Visibility></Visibility>
                            </IconButton>
                            <IconButton
                              style={{ marginLeft: 16 }}
                              onClick={() => {
                                navigate("/surveyor/modifySurveyorDetails/", {
                                  state: { id: choice?.id },
                                });
                              }}
                              sx={{
                                backgroundColor: "#F5E6F5",
                                borderRadius: 4,
                                color: "#1B001B",
                              }}
                              disabled={!hasPermission("Surveyors", "Update")}
                            >
                              <Edit></Edit>
                            </IconButton>
                            <IconButton
                              style={{ marginLeft: 16 }}
                              onClick={() => {
                                setSelectedSurveyor(choice);
                                setOpenDeletePopup(true);
                              }}
                              sx={{
                                backgroundColor: "#F5E6F5",
                                borderRadius: 4,
                                color: "#1B001B",
                              }}
                              disabled={!hasPermission("Surveyors", "Delete")}
                            >
                              <DeleteOutline></DeleteOutline>
                            </IconButton>
                          </ListSubheader>
                        </ListItem>
                        {index !== choicesList?.length - 1 && (
                          <Divider
                            sx={{
                              display: "flex",
                              backgroundColor: "#7C797C",
                              opacity: 0.1,
                            }}
                          />
                        )}
                      </>
                    );
                  }
                )}
              </Box>
            ) : (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                flex={1}
              >
                <Typography>No Data Available</Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </CardContent>

      <CardActions>
        <Grid container>
          <TablePagination
            component="div"
            count={surveyorsListData?.total || 0}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={8}
            rowsPerPageOptions={[]}
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
            }}
          />
          <Grid
            display="flex"
            width={"100%"}
            flexDirection="row"
            justifyContent="space-between"
            m={2}
          >
            <Grid md={4} sm={4} xs={4} lg={2} xl={4}>
              <Button
                onClick={() => navigate("/reports", { replace: true })}
                fullWidth
                sx={{
                  border: "1px solid #91278F",
                  borderRadius: 2,
                  fontWeight: 600,
                  textTransform: "capitalize",
                  color: "#000000",
                }}
              >
                Back To Reports
              </Button>
            </Grid>
            <Grid md={2} sm={4} xs={4} lg={3} xl={4}>
              <Box>
                <Button
                  disabled={!hasPermission("Surveyors", "Create")}
                  variant="contained"
                  onClick={() => {
                    navigate("/surveyor/createNewSurveyor/");
                  }}
                  fullWidth
                  color={"primary"}
                >
                  + Add new surveyor
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </CardActions>
      <DeleteSuryevorPopup
        deleteSurveyor={deleteSurveyor}
        openPopup={openDeletePopup}
        setOpenPopup={setOpenDeletePopup}
      />
    </>
  );
}
